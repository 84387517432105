<template lang="pug">
div
  .d-flex.align-items-center.py-1.px-3
    b-form-checkbox-group#checkbox-group-1.checkbox-group(
      v-model="filter",
      :options="filterOptions",
      plain
    )
    input.form-control(placeholder='Suchbegriff' v-model='query' style='max-width: 400px')

  button.btn.btn-block.btn-secondary(
    @click="showAll = true",
    v-if="!showAll && filteredItems.length > 3"
  )
    | Alle anzeigen ({{ filteredItems.length }})
  button.btn.btn-block.btn-secondary(
    @click="showAll = false",
    v-if="showAll"
  )
    | Weniger anzeigen

  vorgaenge-table(v-model='paginatedItems' @input='fetch')

  button.btn.btn-block.btn-secondary(
    @click="showAll = true",
    v-if="!showAll && filteredItems.length > 3"
  )
    | Alle anzeigen ({{ filteredItems.length }})

  button.btn.btn-block.btn-secondary(
    @click="showAll = false",
    v-if="showAll"
  )
    | Weniger anzeigen
</template>

<script lang='ts'>
import { BFormCheckboxGroup } from "bootstrap-vue"
import vorgaengeFilter from './vorgaengeFilter'
import VorgaengeTable from './VorgaengeTable.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    VorgaengeTable,
    BFormCheckboxGroup,
  },
  props: {
    organisationId: { type: Number, required: true },
  },
  data() {
    return {
      filter: ["akquise", "pflege", "rechnung"],
      query: "",
      showAll: false,
      vorgaenge: [] as schema.Vorgang[],
    }
  },
  computed: {
    filterOptions(): CheckboxOption[] {
      return [
        { text: "Akquisen", value: "akquise" },
        { text: "Pflege", value: "pflege" },
        { text: "Rechnung", value: "rechnung" },
        { text: "Andere", value: "other" },
      ].map(el => {
        return {
          value: el.value,
          text: `${el.text} (${vorgaengeFilter(this.vorgaenge, [el.value], "").length})`,
        }
      })
    },
    paginatedItems(): schema.Vorgang[] {
      if (this.showAll) {
        return this.filteredItems
      } else {
        return this.filteredItems.slice(0, 3)
      }
    },
    filteredItems(): schema.Vorgang[] {
      return vorgaengeFilter(this.vorgaenge, this.filter, this.query)
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
    this.$http
      .get(`/organisationen/${this.organisationId}/vorgaenge.json`)
      .then((res) => {
        this.vorgaenge = res.data.vorgaenge
      })
    }
  }
})
</script>

<style scoped>
</style>
